export enum LocationType {
  Board = 1,
  BoardToken,
  BoardCard,
  Hand,
  Pyramid,
  Reserve,
  Deck,
  Destroyed,
  CardCharacteristics
}
